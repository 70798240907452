//TYPEOGRAPHY
h1, .h1 {
    //color: $secondary;
    color: $primary;
    margin-bottom: rem-calc(32);
    font-weight: $font-weight-bold;
    line-height: 1.125;
    font-size: rem-calc(27);
    font-style: italic;
    text-transform: uppercase;
    padding-top: 50px;
    position: static;

    sup {
        margin-left: 3px;
    }

    span.eyebrow {
        color: $primary;
        font-weight: $font-weight-bold;
        display: block;
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
            @include fontSize(22px);
            line-height: 1.05;
        }
    }

    &:not(.no-line) {
        &:before {
            content: '';
            height: 10px;
            position: absolute;
            left: 0;
            width: 50vw;
            background-color: #2ba3de;
            margin-top: -50px;
            background-image: url(../img/h1-line@2x.png);
            background-position: top right;
            background-size: 6px 10px;
            background-repeat: no-repeat;
        }
    }
    
}
h2, .h2 {
    color: #34A4DC;
    font-weight: $font-weight-medium;
    margin-bottom: rem-calc(22);
}

h3, .h3 {
    //color: $primary;
    font-size: rem-calc(22);
    font-weight: $font-weight-bold;

    //line-height: 1.5;
    margin-bottom: rem-calc(15);
}

h4, .h4 {
    font-size: rem-calc(20);
    font-weight: $font-weight-medium;
}

h1,h2, h4 {
    sup {
        font-size: 60%;
        top: -.6em;
        text-transform: none;
    }
}
h3 {
    sup {
        font-size: 60%;
        top: -.7em;
        text-transform: none;
    }
}

.red-text {
    color: #D22C23;
}

.blue-text {
    color: #003DA5;
}

.white-text {
    color: #ffffff !important;
}

.centered {
    text-align: center !important;
}

.condensed {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
}

.callout {
    position: relative;
    text-align: center;
    margin: 30px auto;
    max-width: 960px;

    &:before,
    &:after {
        content: '';
        height: 4px;
        width: 100%;
        position: absolute;
        background-image: url(../img/callout-border.png);
        background-size: 968px 4px;
        left: 0;
        background-repeat: no-repeat;
    }
    
    p {
        color: #003DA5;
        font-size: 23px;
        margin-bottom: 0 !important;
        padding: 20px 0;
    }
}

.light-blue-text {
    color: #34a4dc;
}

// SPELLOUTS

#spellouts {
    margin-top: rem-calc(20);
    @include make-row();
    &> div {
        @include make-col-ready();
        @include make-col(12);
        p {
          @include fontSize(11px);
          margin-bottom: rem-calc(0);
        }
      }
}
