$breakpoint_desktop: 992px;
$breakpoint_mobile: 991px;

/*
>>================================================================================>
Reusable Elements
>>================================================================================>
*/

/*
All Resolutions
>>------------->
*/

.graphs {
    margin-bottom: 20px;
}


.section-divider {
    height: 10px;
    position: absolute;
    left: 0;
    width: 50vw;
    background-color: #2ba3de;
}

picture {
    &.fluid-image {
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            display: block;
        }
    }

    &.responsive-image {
        max-width: 100%;
	    height: auto;
    }

    &.centered {
        display: block;
        margin: 0 auto;

        img {
            display: block;
            margin: 0 auto;   
        }
    }
}

/*
Desktop
>>------------->
*/

@media screen and (min-width: 992px) {
    .mobile-only {
        display: none;
    }

    .suspension-vs-ointments {
        .desktop-only {
            margin-top: -30px;
        }
    }
}

/*
Mobile
>>------------->
*/

@media screen and (max-width: 991px) {
    .desktop-only {
        display: none;
    }

    .suspension-vs-ointments {
        .suspension,
        .ointment {
            background-size: cover;
            color: #fff !important;
            padding: 20px;
        }

        .title {
            margin-bottom: 5px;
            font-weight: bold;
        }

        p {
            margin-bottom: 0 !important;
        }

        .suspension {
            background-image: url(../img/suspension-vs-ointments-suspension.jpg)
        }

        .ointment {
            background-image: url(../img/suspension-vs-ointments-ointment.jpg)
        }
    }
}

/*
>>================================================================================>
Section: Billboard
>>================================================================================>
*/

#billboard {

    /*
    All Resolutions
    >>------------->
    */

    position: relative;

    img {
        max-width: 100%;
        height: auto;
    }

    .container {
        position: relative;
    }

    button {
        background-color: transparent;
        border: none;
    }

    /*
    Desktop
    >>------------->
    */

    @media screen and (min-width: 992px) {
        min-height: 495px;
        height: 47vw;
        max-height: 810px;
        

        .down-arrow {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }

        video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        .container {
            display: flex;
            align-items: center;
            height: 100%;

            > div {
                margin-left: auto;
            }
        }
    }

    /*
    Mobile
    >>------------->
    */

    @media screen and (max-width: 991px) {
        background-color: #000;

        .billboard-title {
            img {
                display: block;
                margin: 0 auto;
            }
        }

        .down-arrow {
            display: none;
        }

        video {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }

        .container {
            padding: 20px;
        }
    }
}

/*
>>================================================================================>
Circular Chart
>>================================================================================>
*/

#circular-chart {

    /*
    All Resolutions
    >>------------->
    */

    position: relative;

    .title {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .chart {
        img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    
    .label {
        &.one {
            li:before,
            .title {
                color: #EC2C5B;
            }
        }
    
        &.two {
            li:before,
            .title {
                color: #D22C23;
            }
        }
    
        &.three {
            li:before,
            .title {
                color: #FFAD47;
            }
        }
    
        &.four {
            li:before,
            .title {
                color: #FF6520;
            }
        }
    }
    

    /*
    Desktop
    >>------------->
    */

    @media screen and (min-width: $breakpoint_desktop) {
        .label {
            position: absolute;
            top: 0;
            width: 25%;
        }

        #circular-chart {

        }

        .chart {
            width: 50%;
            margin: 0 auto;
    
            img {
                width: 100%;
            }

            @media screen and (max-width: 1200px) {
                padding-top: 70px;
            }
        }

        .chart {
            
        }

        .label {
            position: absolute;
            top: 0;
            width: 25%;

            &.one {
                top: 20%;
            }

            &.two {
                top: 62%;

                @media screen and (max-width: 1200px) {
                    top: 70%;
                }
            }

            &.three {
                top: 20%;
                right: 0;
            }

            &.four {
                top: 62%;
                right: 0;

                @media screen and (max-width: 1200px) {
                    top: 70%;
                }
            }
        }
    }

    /*
    Mobile
    >>------------->
    */

    @media screen and (max-width: $breakpoint_mobile) {
        
    }
}

/*
>>================================================================================>
Section: Callout 2
>>================================================================================>
*/

#callout-2 {

    /*
    All Resolutions
    >>------------->
    */

    text-align: center;
    color: #fff;
    max-width: 644px;
    margin-right: auto;
    margin-left: auto;

    .title {
        font-weight: bold;
        font-style: italic;
    }

    a {
        &:hover {
            color: #00b5e2;
        }
    }

    /*
    Desktop
    >>------------->
    */

    @media screen and (min-width: $breakpoint_desktop) {
        .title {
            font-size: 50px;
            margin-bottom: 10px;
        }
    }

    /*
    Mobile
    >>------------->
    */

    @media screen and (max-width: $breakpoint_mobile) {
        .title {
            font-size: 40px;
            margin-bottom: 10px;
        }
    }
}