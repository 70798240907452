// BLUE

.blue {
    background: $primary; //debug
    //@include make-container();
    //@include make-container-max-widths;
    border-bottom: 4px solid $white;
    padding-top: rem-calc(70);
    padding-bottom: rem-calc(70);
    background-image: url(../img/blue-background.jpg);
    background-size: cover;

    .light-blue-text,
    h2 {
        color: #9ad5ea;
    }

    @at-root .difference-callout & {
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);
    }
    
    &__row {
        //border: 1px dashed green;
        @include make-row();
        //align-items: baseline;
        //margin-top: rem-calc(-10);
        //margin-bottom: rem-calc(20);
        h1 {
            color: $primary-light-3;
        }
        p, h2, h3, ul {
            color: $white;
            margin-bottom: 2rem;
            text-align: left;

            &.eyebrow {
                text-transform: uppercase;
                font-size: rem-calc(12);
                margin-bottom: 1rem;
                color: $white;
            }
            &.no-bottom-space {
                margin-bottom: 0;
            }
        }
        dl {
            color: $white;
        }
        //background: lime;
        //border: 1px dashed silver;
        //max-width: rem-calc(680);//85%;//
        //margin-left: auto;
        //margin-right: auto;
    }
    h2 {
        color: #9ad5ea;
    }
    &__cols-full {

        //background: black; //debug
        @include make-col-ready();
        @include make-col(12);
        &--callout {
            margin-top: rem-calc(70);
            margin-bottom: rem-calc(70);
            text-align: center;
            .has-lines,
            p {
                border-top: 2px solid $cyan;
                border-bottom: 2px solid $cyan;
                text-align: center;
                padding: 2rem 0;
                margin-bottom: 0;
                @include media-breakpoint-up(md) {
                    padding: 2rem 20%;
                }
            }

            .has-lines {
                h1 {
                    color: $white;
                }
            }
            .btn-success {
                &:hover {
                    background-color: $white !important;
                    color: $secondary !important;
                }
            }
        }
    }
    &__cols-six {
        @include make-col-ready();
        @include make-col(12);
        text-align: center;
        @include media-breakpoint-up(lg) {
            text-align: left;
            @include make-col(6);
        }
        img {
            &.fluid {
                max-width: rem-calc(290);
                width: 100%;
            }
        }
    }
    &__cols-five {
        //background: black; //debug
        @include make-col-ready();
        @include make-col(12);
        @include make-col-offset(0);
        text-align: center;
        margin-top: rem-calc(70);
        @include media-breakpoint-up(md) {
            //@include make-col(6);
            //@include make-col-offset(0);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(6);
            text-align: left;
            margin-top: 0;
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(xl) {
            @include make-col(5);
            @include make-col-offset(1);
        }
    }

    &__cols-four {
        @include make-col-ready();
        @include make-col(12);
        margin-top: 1rem;
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
        .media {
            img {
                margin-right: 1rem;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
    &__cols-flex {
        //background: green; //debug
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(100);
        @include make-col-ready();
        @include make-col(12);
        display: flex;
        flex-flow: row wrap;
        &>div {
            //border: 1px dotted gold; //debug
            &:first-child {
                //background: red; //debug
                flex: 100% 0 0; // stretch full width
                @include media-breakpoint-up(md) {
                    //background: pink; //debug
                    flex: auto 0 0; //fit to content
                    margin-right: rem-calc(40);
                }
            }
            &:last-child {
                flex: 1; //stretch remaining width
                //background: black; //debug
                display: flex;
                align-items: center;
                margin-top: rem-calc(30);
                @include media-breakpoint-up(md) {
                    margin-top: rem-calc(-30);
                }
            }
            div.vs {
                //background: gold; //debug
                text-align: center;
                display: flex;
                justify-content: center;
                &>div {
                    &:nth-child(odd) {
                        //background: orange; //debug
                        flex: auto 0 0;
                        p {
                            margin-top: rem-calc(15);
                        }
                    }
                    &:nth-child(even) {
                        //background: purple; //debug
                        margin: rem-calc(20) rem-calc(25) 0 rem-calc(10);
                    }
                }
            }
        }
    }
}
