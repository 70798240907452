// REFERENCES

#references {
    margin-top: rem-calc(20);
    @include make-row();
    &> div {
        @include make-col-ready();
        @include make-col(12);
        p {
          @include fontSize(11px);
          margin-bottom: rem-calc(0);
        }
      }
}
