// FOOTER

footer {
    background: $gray-400;
    color: $gray-700;
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    margin-top: rem-calc(50);
    .container {
        &> div {
            @include make-row();
            align-items: center;

            .eyevance-logo {
                margin-bottom: rem-calc(35);
                @include make-col-ready();
                @include make-col(12);
                @include media-breakpoint-up(lg) {
                    @include make-col(2);
                    // background: yellow;
                    margin-bottom: 0;
                }
            }

            .copyright {
                @include make-col-ready();
                @include fontSize(11px);
                //font-weight: $font-weight-light;
                @include make-col(12);
                margin-bottom: rem-calc(30);
                @include media-breakpoint-up(lg) {
                    @include make-col(6);
                    margin-bottom: 0;
                }
            }

            .menu {
                @include make-col-ready();
                @include make-col(12);
                font-weight: $font-weight-medium;
                @include media-breakpoint-up(lg) {
                    @include make-col(4);
                    //background: pink;
                }
                ul {
                    margin-left: rem-calc(-8);
                    @include media-breakpoint-up(lg) {
                        float: right;
                        margin-left: auto;
                    }
                    li {
                        @include fontSize(11px);
                        &:not(:first-child)::before {
                            content: " | ";
                            margin-right: rem-calc(-2);
                            color: $secondary;
                        }
                        .nav-link {
                            display: inline-block;
                            padding-left: rem-calc(8);
                            padding-right: rem-calc(8);
                            //color: $primary;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}
