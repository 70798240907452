// Scroll Arrow
.scroll-arrow {
    //background: rgba(200, 200, 200, 0.5);//debug
	//margin-top: -30px;
	//margin-bottom: -30px;
	height: 0;
    text-align: center;
    img {
        width: 60px;
        width: 60px;
    	margin-top: -25px;
    }
}
