// GRID

.container {
  @include make-container();
  @include make-container-max-widths;
  //border: 1px dashed silver;
}

[class*="__container"] {
  @include make-container();
  @include make-container-max-widths();
}
