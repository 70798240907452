// Global

html {
	overflow-x: hidden;
}

body {
	margin-left: auto;
	margin-right: auto;
	overflow-x: hidden!important;
	-ms-overflow-style-x: hidden!important;
}

a {
	color: #00B5E2;
}

.nowrap {
	white-space: nowrap;
}

ul.bullets {
	list-style: none;
	padding-left: rem-calc(16);
	li {
		//background: gold;//debug
		margin-bottom: $headings-margin-bottom;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			content: "•";
			color: $cyan;
  			display: inline-block;
			padding-right: rem-calc(10);
  			margin-left: rem-calc(-16);
		}
		ul {
			margin-top: rem-calc(10);
			li {
				background: rgba(0, 0, 0, 0) url("../img/bullet2.svg") no-repeat scroll 1px 8px;
				background-size: 5px 5px;
				padding-left: 1rem;
				&:not(:last-child) {
					//margin-bottom: 0;
				}
				&:before {
					content: "";
					padding-right: 1rem;
				}
			}
		}
	}
	&.no-space {
		margin-top: rem-calc(-10);
	}
	&.no-bottom-space {
		margin-bottom: 0;
	}
}

.letters,
.references {
	//background: red; //debug
	@include fontSize(11px);
	margin-bottom: rem-calc(0);
	font-weight: $font-weight-normal;
	counter-reset: listStyle;
	dt,
	li {
		position: relative;
		font-weight: $font-weight-normal;
	}
	// To have list start at b use skip class on 1st dt
	dt.skip{
		visibility: hidden;
		font-size: 0!important;
		height: 0!important;
		line-height: 0!important;
		padding: 0!important;
		margin: 0!important;
	}
	dt:before {
		content: counter(listStyle, lower-alpha);
		counter-increment: listStyle;
		position: absolute;
		left: 0;
		top: 0;
		//font: bold 50px/1 Sans-Serif;
		font-size: 70%;
		top: -0.4em;
	}
	dt, dd {
		padding-left: 5px;
		//padding-bottom: .3rem;
		margin-bottom: $headings-margin-bottom;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.references {
	list-style: none;
	padding-left: 0;
	margin-left: 0;

	li {
		margin-bottom: $headings-margin-bottom;
	}
}

.spacer {
	height: 3rem;
	@include media-breakpoint-down(md) {
		height: 2rem;
	}
}

main {
	margin-bottom: rem-calc(25);
	a {
		font-weight: $font-weight-bold;
	}
}
