// ENTRY CONTENT
main {
  //margin-top: rem-calc(42);
  @include media-breakpoint-up(md) {
    //margin-top: rem-calc(60);
  }
  .entry-content {
    @include make-row();
    .container {
      &> div {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(lg) {
          @include make-col(10);
          @include make-col-offset(1);
        }
      }
    }
  }
}