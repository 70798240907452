// HEADER

header {
    background: $black;
    /* min-height needed so page does not skip when sticky-menu becomes fixed postion */
    min-height: rem-calc(110);
    @include media-breakpoint-up(lg) {
        min-height: rem-calc(144);
    }

    .container {
        position: relative;
        justify-content: flex-end;
        //background: orange;//debug
        &> div {
            //height: 167px;
        }
        //logo
        #logo {
            display: inline-block;
            position: absolute;
            z-index: $zindex-sticky;
            top: rem-calc(0);
            left: 0;
            right: 0;
            text-align: center;
            margin-top: rem-calc(15);
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-up(lg) {
                top: rem-calc(0);
                left: $grid-gutter-width/2;
                right: auto;
                margin-top: rem-calc(23);
                text-align: left;
            }
            img {
                width: 123px;
                height: 80px;
                @include media-breakpoint-up(lg) {
                    width: 152px;
                    height: 98px;
                }
            }
        }

        //Utility Menu
        #utility {
            //background: blue;//debug
            @include make-row();
            &> div {
                //background: green;//debug
                width: 100%;
                @include media-breakpoint-up(lg) {
                    @include make-col-ready();
                    @include make-col(12);
                    width: auto;
                }
                ul {
                    //background: yellow;//debug
                    //border: 1px dotted purple; //debug
                    justify-content: flex-end;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                    li {
                        //background: silver;//debug
                        padding-top: rem-calc(12);
                        background: #3B3B3E;
                        a {
                            display: inline-block;
                            font-family: $font-family-condensed;
                            font-weight: $font-weight-bold;
                            @include fontSize(14px);
                            color: $white;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &:first-child {
                            padding-left: rem-calc(12);
                        }
                        &:last-child {
                            padding-right: rem-calc(12);
                        }
                        &:not(:first-child)::before {
                            content: " | ";
                            margin-right: rem-calc(-2);
                            color: $white;
                        }
                    }
                }
            }
        }

        //primary Menu
        #primary-navigation {
            //border: 1px dotted green; //debug
            //background: lime;//debug
            @include make-row();
            &> nav {
                //border: 1px dotted orange; //debug
                @include make-col-ready();
                @include make-col(12);

                ul.nav {
                    //background: gold;//debug
                    justify-content: flex-end;
                    //margin-right: -$grid-gutter-width/2;
                    @include media-breakpoint-down(md) {
                        background: $gray-900;
                        //border: 1px dotted pink; //debug
                        display: none;
                        position: absolute;
                        width: rem-calc(768);
                        left: rem-calc(-25);
                        top: rem-calc(110);
                        z-index: $zindex-dropdown;
                        padding:  0 ;
                        padding-left: 0;//rem-calc(35);
                        padding-right: 0;//rem-calc(35);
                        box-shadow: 0 5px 7px 1px rgba(0,0,0,.6);
                    }
                    @include media-breakpoint-down(sm) {
                        //background: plum;//debug
                        left: 0;
                        width: 100%;
                        //padding-left: rem-calc(25);
                        //padding-right: rem-calc(25);
                    }
                    @include media-breakpoint-up(lg) {
                        //background: salmon;//debug
                        margin-top: rem-calc(48);
                        //border: 1px dotted blue; //debug
                    }
                    @include media-breakpoint-up(xl) {
                        //background: pink;//debug
                    }

                    &>li {
                        text-align: left;
                        //background: gray;//debug
                        //border: 1px dotted orange; //debug
                        text-transform: uppercase;
                        border-bottom: transparent rem-calc(12) solid;
                        border-top: transparent rem-calc(2) solid;
                        //transition: border 2s;
                        @include media-breakpoint-up(lg) {
                            text-align: center;
                            &.has-submenu {
                                position: relative;
                                min-width: rem-calc(100);
                            }
                        }
                        &.has-submenu {
                            border-bottom: none;
                            ul li a {
                                border-top: none;
                            }
                            &:hover {
                                @include media-breakpoint-down(md) {
                                    background: none;
                                }
                            }
                        }
                        a {
                            //background: gray;//debug
                            border-top: transparent rem-calc(2) solid;
                            color: $white;
                            @include fontSize(14px);
                            font-weight: $font-weight-bold;
                            @include media-breakpoint-down(md) {
                                padding: rem-calc(18) 5% rem-calc(6);
                            }
                            @include media-breakpoint-up(lg) {
                                padding: rem-calc(6) rem-calc(0) rem-calc(12);
                                margin-left: rem-calc(5);
                                margin-right: rem-calc(5);
                                color: $white;
                                @include fontSize(15px);
                            }
                            @include media-breakpoint-up(xl) {
                                margin-left: rem-calc(30);
                                margin-right: rem-calc(30);
                            }
                        }
                        // last menu item on desktop
                        &:nth-last-child(3){
                            //margin-top: rem-calc(25);
                            //background: pink;//debug
                            a {
                                margin-right: 0;
                            }
                        }
                        // ISI
                        &:nth-last-child(2){
                            margin-top: rem-calc(25);
                        }
                        // last menu items (shown on mobile only)
                        &:nth-last-child(-n+2) {
                            //background: red;//debug
                            a {
                                font-family: $font-family-condensed;
                                @include fontSize(12px);
                            }
                            @include media-breakpoint-up(lg) {
                                display: none;
                            }
                        }
                        &:hover {
                            background: $gray-600;
                            transition: background 1s ease 0s;
                            @include media-breakpoint-up(lg) {
                                background: inherit;
                                border-bottom: transparent rem-calc(12) solid;
                                border-top: transparent rem-calc(2) solid;
                                a {
                                    color: $primary-light-3;
                                    border-top: $primary-light-3 rem-calc(2) solid;
                                    transition: all .5s ease 0s;
                                }
                                ul {
                                    display: block !important;
                                    cursor: pointer;
                                }
                            }
                        }
                        &.active {
                            background: $gray-600;
                            a {
                                color:  $white;
                            }
                            @include media-breakpoint-up(lg) {
                                background: transparent;
                                a {
                                    color: $primary-light-3;
                                    border-top: $primary-light-3 rem-calc(2) solid;
                                    //transition: all .5s ease 0s;
                                }
                            }
                        }
                        // submenu
                        &>ul {
                            list-style: none;
                            // IE Edge bug
                            list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
                            display: block;
                            margin-top: rem-calc(10);
                            margin-left: rem-calc(-40);
                            li {
                                text-transform: none;
                                text-align: left;
                                //border-bottom: transparent rem-calc(12) solid;
                                a {
                                    color: $white;
                                    font-weight: $font-weight-normal;
                                    text-decoration: none;
                                    display: block;
                                    padding:  rem-calc(15) rem-calc(20) rem-calc(15) rem-calc(60) ;
                                }
                                &:hover {
                                    //background: $primary-light-3;
                                    a {
                                        transition: background 1s ease 0s;
                                        background: $gray-600;
                                        //font-weight: $font-weight-bold;
                                        //font-style: italic;
                                    }
                                    //text-decoration: none;
                                }
                                &.active {
                                    a {
                                        background: $gray-600;
                                        color: $white;
                                        //font-weight: $font-weight-bold;
                                        //font-style: italic;
                                    }
                                }
                            }

                            @include media-breakpoint-up(lg) {
                                display: none;
                                position: absolute;
                                //background-color: lime;
                                min-width: rem-calc(100);
                                z-index: $zindex-dropdown;
                                left: 0;
                                top: rem-calc(50);
                                margin: 0;
                                padding: 0;
                                li {
                                    margin: 0;
                                    a {
                                        color: $white!important;//primary
                                        padding:  rem-calc(7) rem-calc(20);
                                        // margin-left: rem-calc(55) ;
                                        margin-right: 0;
                                        background: $black;//$primary-light-2;
                                    }
                                    &:hover {
                                        //background: $gray-600;// $primary-light-3 !important;
                                        transition: background 1s ease 0s;
                                        a {
                                            background: $gray-600;//$primary-light-3 !important;
                                            //color: $primary !important;
                                            //font-weight: $font-weight-bold;
                                            //font-style: italic;
                                        }
                                        //text-decoration: none;
                                    }
                                }
                            }
                        }

                    }
                }

                //Show Menu
                .menu-toggle.up {
                    @include media-breakpoint-down(md) {
                        &+ ul {
                            display: block;
                            ul {
                                display: block !important;
                            }
                        }
                    }
                }
            }
        }

    }
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
#sticky-menu.sticky {
    background: $black;
    position: fixed;
    z-index: $zindex-fixed;
    top: -132px;
    width: 100%;
    //left: -50%!important;
    //transform: translateX(50%)!important;
    transform: translateY(50px)!important;
    transition: transform 1s ease 0s;
    nav ul.nav  {
        justify-content: space-evenly!important;
        &>li {
            @include media-breakpoint-up(lg) {
                border-bottom: none!important;
            }
            // last menu item on desktop
            &:nth-last-child(3) {
                a {
                    margin-right: rem-calc(55) !important;
                }
                @include media-breakpoint-up(xl) {
                    a {
                        margin-right: rem-calc(75) !important;
                    }
                }
            }
            &>ul  {
                top: rem-calc(38) !important;
            }
        }
    }
    #logo {
        display: none !important;
    }
    @include media-breakpoint-down(md) {
        background: transparent;
        top: -50px;
        transform: translateY(50px)!important;
        .menu-toggle.up {
            background: $primary-light-3;
            top: rem-calc(0);
        }
        #primary-navigation>nav ul.nav {
            top: rem-calc(80);
            //background: red;//$black;
        }
    }
}
/* Hack for using anchor links with a fixed header is a problem */
.page-section:before {
    content: "";
    display: block;
    padding-top: 0; /* header height */
    margin-top: 0;  /* header height to offset padding*/
    @include media-breakpoint-up (lg) {
        padding-top: 50px; /* header height */
        margin-top: -50px; /* header height to offset padding*/
    }
}
