// MEDIA OBJECT

.media {
    img {
        &.top {
            align-self: flex-start;
        }
        &.middle {
            align-self: center;
        }
        &.bottom {
            align-self: flex-end;
        }
        margin-right: rem-calc(35);
    }
    .media-body {
        &.top {
            align-self: flex-start;
        }
        &.middle {
            align-self: center;
        }
        &.bottom {
            align-self: flex-end;
        }

    }

    &.stacked {
        @include media-breakpoint-down(md) {
            display: block;
            text-align: center;
            img {
                margin-right: 0;
                margin-bottom: rem-calc(25);
            }
        }
        .media-body {
            text-align: center;
            @include media-breakpoint-up(md) {
                text-align: left;
            }
            :not(a) {
                text-align: left;
            }
            &:nth-child(3) {
                //background:orange; //debug
                @include media-breakpoint-down(md) {
                    margin-top: rem-calc(25);
                }
            }
            .media-pad {
                @include media-breakpoint-up(md) {
                    padding-right: rem-calc(45);
                }

            }
        }
    }

}

#media-stack {
    //border: 1px dashed red;
    //margin-top: rem-calc(55);
    @include media-breakpoint-up(xl) {
        //padding-right: rem-calc(165);
        //@include make-col-offset(2);
    }
    .media-body {
        //margin-bottom: rem-calc(85);
        //border: 1px dashed green;
    }
}
