// WHITE

.white {
    //background: gold;//debug
    //@include make-container();
    //@include make-container-max-widths;
    &__row {
        @include make-row();
        //align-items: baseline;
        //margin-top: rem-calc(-10);
        //margin-bottom: rem-calc(20);
        padding-top: rem-calc(70);
        padding-bottom: rem-calc(70);
        p, h2 {
            margin-bottom: 2rem;
            &.eyebrow {
                text-transform: uppercase;
                font-size: rem-calc(12);
                margin-bottom: 1rem;
                color: rgba(59,59,62,0.67);
            }
        }
        //border: 1px dashed green;
        //background: lime;
        //border: 1px dashed silver;
        //max-width: rem-calc(680);//85%;//
        //margin-left: auto;
        //margin-right: auto;
    }

    &__icon {
        //background: gray;//debug
        //border: 1px dashed purple;
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
            @include make-col-ready();
            @include make-col(1);
            //padding-left: $grid-gutter-width / 2;
            //padding-right: $grid-gutter-width / 2;
            //background: silver;
            //margin-top: rem-calc(-60);
            //    border: 1px dashed orange;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        //text-align: right;
        //padding-left: 0;
        //padding-right: 0;
        //    padding-top: rem-calc(20);
        //@include make-col-offset(1);
        img {
            margin-top: rem-calc(-20);
        }


        @include media-breakpoint-up(md) {
            //border: 1px dashed blue;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(lg) {
            //border: 1px dashed brown;
            //@include make-col(4);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(xl) {
            //border: 1px dashed yellow;
            //@include make-col(3);
            //@include make-col-offset(3);
        }

    }
    /* about */
    &__cols-extend {
        //border: 1px dashed red;
        //background: yellow; //debug
        //text-align: left;

        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(sm) {
            @include make-col(11);
            //    border: 1px dashed orange;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(md) {
            //border: 1px dashed blue;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(lg) {
            //border: 1px dashed brown;
            //@include make-col(10);
            //@include make-col-offset(2);
        }
        @include media-breakpoint-up(xl) {
            //border: 1px dashed yellow;
            //@include make-col(3);
            //@include make-col-offset(3);
        }
    }
    &__cols-indent {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(sm) {
            @include make-col(11);
            @include make-col-offset(1);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(10);
        }
        h3 {
            //margin-bottom: 0;
            border-bottom: 2px solid $cyan;
        }
    }
    /* preclinical */
    &__cols-full {
        @include make-col-ready();
        @include make-col(12);
        position: static;
    }
    &__cols-one {
        //background: black; //debug
        @include make-col-ready();
        @include make-col(12);
        text-align: center;
        margin: 0 0 rem-calc(20);
        //padding: 0;
        @include media-breakpoint-up(md) {
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(lg) {
            margin: 0;
            @include make-col(1);
            div.circle {
                margin-top: rem-calc(-7);
            }
            //border: 1px dashed orange;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        // second circle
        &:nth-of-type(4) {
            //background: red; //debug
            margin-top: rem-calc(80);
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
    }
    &__cols-half {
        //background: yellow; //debug
        @include make-col-ready();
        @include make-col(12);
        text-align: center;
        margin-bottom: 30px;
        
        p, h2, h3 {
            text-align: left;
        }
        img {
            max-width: rem-calc(350);
            // width: 100%;
            margin-top: rem-calc(20);
        }
        @include media-breakpoint-up(md) {
            //border: 1px dashed blue;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(lg) {
            text-align: left;
            @include make-col(6);
            //border: 1px dashed orange;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(xl) {
            //@include make-col(4);
            //border: 1px dashed orange;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
    }
    
    .chart-callout {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;

            .callout {
                margin-left: 20px;

                span {
                    margin-top: -40px;
                }
            }
        }

        .chart {
            margin: 0 auto;
        }

        .callout {
            span {
                max-width: 200px;
                display: block;
                text-align: center;
                background-color: $primary;
                color: $white;
                border-radius: 25px;
                padding: 10px;
            }
        }

        @include media-breakpoint-down(md) {
            .callout {
                margin: 20px 0;
            }

            .chart {
                padding: 0 20px;
                img {
                    margin-left: -40px;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            .callout {

                span {
                    font-size: 14px;
                    margin: 0 auto;
                }
            }
        }

        
    }
    /* dosing */
    &__cols-middle {
        //border: 1px dashed red;
        //background: yellow; //debug
        //text-align: left;

        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(sm) {
            //border: 1px dashed orange;
            @include make-col(11);
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(md) {
            //border: 1px dashed blue;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(7);
            //@include make-col(11);
            //border: 1px dashed brown;
            //@include make-col(10);
            //@include make-col-offset(2);
        }
        @include media-breakpoint-up(xl) {
            //@include make-col(6);
            //border: 1px dashed green;
            //@include make-col(3);
            //@include make-col-offset(3);
        }
    }
    &__cols-end {
        //border: 1px dashed red;
        //background: yellow; //debug
        text-align: center;
        margin-top: rem-calc(20);
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(sm) {
            //    border: 1px dashed orange;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(md) {
            //border: 1px dashed blue;
            //@include make-col(5);
            //@include make-col-offset(1);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(4);
            @include make-col-offset(0);
            margin-top: 0;
            text-align: right;
            //border: 1px dashed brown;
            //@include make-col(10);
            //@include make-col-offset(2);
        }
        @include media-breakpoint-up(xl) {
            //@include make-col-offset(1);
            //border: 1px dashed green;
            //@include make-col(3);
            //@include make-col-offset(3);
        }
    }
}
