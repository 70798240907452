// ISI

#isi {
    margin-bottom: rem-calc(50);
    @include make-row();
    //border: 1px dashed green;
    //background: pink;

    &> div {
        //border: 1px dashed purple;
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(lg) {
            @include make-col(12);
        }
        .h2 {
            margin-top: rem-calc(30);
            margin-bottom: rem-calc(20);
        }
        .h3 {
            color: $body-color;
            font-size: $font-size-base;
            margin-top: rem-calc(30);
            margin-bottom: rem-calc(0);
        }
        .no-top-margin {
            margin-top: rem-calc(0);
            //background: pink;
        }
    }

}

#sticky-isi {
  //border: 1px solid red;
  height: rem-calc(230);
  width: 100%;
  position: fixed;
  bottom: 0;
  background: $white;
  box-shadow: 0 0 35px 6px rgba(0, 0, 0, 0.15);
  transition: opacity 0.4s ease, visibility 0.4s ease, 0.4s height ease-in-out;
  z-index: $zindex-isi;
  left: 50%;
  transform: translate(-50%, 0);
  #isi {
      &> div {
      //border: 1px dashed yellow;
      @include make-col(11);
      @include media-breakpoint-up(lg) {
          @include make-col(12);
      }
      .h2 {
          margin-top: rem-calc(20) !important;
          margin-bottom: rem-calc(10)!important;
      }
      .h3 {
          margin-top: rem-calc(10)!important;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    //border: 1px solid yellow;
    height: rem-calc(190);
  }

  @include media-breakpoint-up(md) {
    height: rem-calc(150);
  }

  @include media-breakpoint-up(lg) {
    height: rem-calc(150);
  }

  @include media-breakpoint-up(xl) {
    height: rem-calc(130);
  }

  &.disabled {
    visibility: hidden;
    opacity: 0;
  }

  &.open {
    z-index: $zindex-isi-open;
    transition: all 0.4s ease-in-out;
    height: 100%;
    overflow: scroll;

    .isi-toggle img {
      transition: all 0.4s ease-in-out;
      transform: rotate(45deg);
    }
  }

  .isi-toggle {
    //border: 1px solid yellow;
    //height: 20px;
    @include make-container;
    @include make-container-max-widths;
    position: relative;
    z-index: $zindex-dropdown;

    img {
      //border: 1px solid green;
      position: absolute;
      display: block;
      top: 16px;
      right: 16px;
      cursor: pointer;
      transition: all 0.4s ease;
    }
  }
}
