// BUTTONS

.btn {
    @include fontSize(18px);
    width: rem-calc(200);
    &.btn-success {
        color: $white;
        background: $secondary;
        letter-spacing: rem-calc(.2);
        font-weight: $font-weight-medium;
        border-radius: rem-calc(24.5);
        &::before {
            content: '';
            display: block;
            height: 0;
            width: 0;
            margin-top: calc((1.3 - #{$line-height-base}) * 0.5em);
        }
        &:focus {
            //border-color: $red !important;
        }
    }
    &.btn-outline-secondary  {
        color: $secondary;
        //border-color: $secondary;
        // text-transform: uppercase;
        letter-spacing: rem-calc(.2);
        font-weight: $font-weight-medium;
        border-radius: rem-calc(24.5);
        border-width: 2px;
        &::before {
            content: '';
            display: block;
            height: 0;
            width: 0;
            margin-top: calc((1.3 - #{$line-height-base}) * 0.5em);
        }
    }
    &:hover, &:active {
        //opacity: .8;
        color: $white !important;
        background: $primary !important;
        border-color: $primary !important;
    }
    &:focus {
        box-shadow: none !important;
    }
}
