// NAV ICON

.menu-toggle {
    z-index: $zindex-sticky;
    @include media-breakpoint-up(lg) {
        display: none;
    }
    background: $primary-light-3;
    border: 0;
    width: 55px;
    height: 60px;
    position: absolute;
    top: rem-calc(0);
    right: rem-calc(0);
    cursor: pointer;
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 35px;
        background: $primary;
        border-radius: rem-calc(3);
        opacity: 1;
        left: 10px;
        //transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 17px;
        }
        &:nth-child(2) {
            top: 29px;
        }
        &:nth-child(3) {
            top: 41px;
        }
    }
    &.up {
        span {
            &:nth-child(1) {
                top: 28px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                top: 28px;
                width: 0%;
                left: 50%;
            }
            &:nth-child(3) {
                top: 28px;
                transform: rotate(-45deg);
            }
        }
    }
    &:focus {
        outline: none;
    }
}
