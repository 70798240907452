.container {
    @media screen and (max-width: 1410px) and (min-width: 1025px) {
        padding-left: 150px;
        padding-right: 150px;

        .white__cols-one {
            .circle {
                width: 50px !important;
                height: 50px;
                font-size: .8rem;
            }
        }
    }
}

#footer {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.billboard {
    &__video-cta {

        /*
        All Resolutions
        >>------------->
        */

        color: $white;

        /* Button Resets */

        display: flex;

        &::-moz-focus-inner {
            border-style: none;
            padding: 0;
        }

        &:-moz-focusring {
            outline: 1px dotted ButtonText;
        }

        &:focus {
            outline:0;
        }

        .label {
            display: flex;
            align-items: center;
            color: #FFFFFF;

            .symbol {
                flex-shrink: 0;
            }

            .copy {
                text-align: left;
                font-weight: bold;

                .line-1 {
                    display: block;
                    font-weight: normal;
                }
        
                .line-2 {
                    span {
                        color: #99D6EA;
                        font-style: italic;
                    }
                }
            }
        }

        .play {
            position: relative;
            border-radius: 50%;
            transition: all 300ms ease;
            z-index: 1;

            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                fill: #99D6EA;
                transition: fill 300ms ease;
            }
        }

        &:hover {
            .play {
                border-color: #fff;

                svg {
                    fill: #ffffff;
                }
            }
        }

        /*
        Desktop
        >>------------->
        */

        @media screen and (min-width: 768px) {
            left: 0;
            background-color: transparent;
            padding: 0;
            z-index: 1024;
            width: 197px;
            height: 74px;
            background-image: url(../img/video-cta.png);
            background-size: 197px 74px;
            background-position: right center;
            transition: transform 300ms ease;
            position: fixed;
            top: 338px;
            padding-left: 13px;

            @at-root .video-played & {
                transform: translateX(-100%);
            }

            @media screen and (min-width: 1025px) and (max-height: 770px) {
                top: 50%;
                transform: translateY(-50%);
            }

            @media screen and (max-width: 1024px) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
                background-image: url(../img/video-cta@2x.png);
            }

            .line-2 {
                display: block;
            }

            .label {
                // margin-left: 70px;
                padding-top: 15px;

                .symbol {
                    margin-right: 14px;

                    // @media screen and (max-width: 1600px) {
                    //     margin-right: 8px;
                    // }
                }

                .copy {
                    font-size: 15px;
                    line-height: 17px;
                    letter-spacing: 0.18px;

                    .line-1 {
                        font-size: 11px;
                        line-height: 10px;
                    }
            
                    .line-2 {
                        span {
                            font-size: 18px;
                        }
                    }
                }
            }

            .play {
                width: 35px;
                height: 35px;
                border: 2px solid #99D6EA;

                svg {
                    width: 12px;
                    height: 17px;
                    margin-left: 2px;
                }
            }
        }

        /*
        Mobile
        >>------------->
        */

        @media screen and (max-width: 767px) {
            background-color: #003da5;
            width: 100%;
            z-index: 1011;
            position: relative;
            padding: 10px;

            .label {
                justify-content: center;

                .symbol {
                    margin-right: 10px;
                }

                .copy {
                    font-size: 20px;
                    line-height: 20px;
                    letter-spacing: 0.18px;

                    .line-1 {
                        font-size: 15px;
                        line-height: 15px;
                        margin-bottom: 2px;
                    }
            
                    .line-2 {
                        span {
                            font-size: 23px;
                            margin-right: 4px;
                        }
                    }
                }
            }

            .play {
                width: 45px;
                height: 45px;
                border: 3px solid #99D6EA;

                svg {
                    width: 15px;
                    height: 20px;
                    margin-left: 3px;
                }
            }
        }
    }
}

/*
>>================================================================================>
Video Player
>>================================================================================>
*/

.video-player {

    /*
    All Resolutions
    >>------------->
    */

    display: block;
    margin: 0 auto 30px auto;
    position: relative;
    border: 2px solid #0098e9;

    video {
        outline: 0;
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    &.playing {
        .play {
            opacity: 0;
            visibility: hidden;
        }
    }

    /*
    Desktop
    >>------------->
    */

    @media screen and (min-width: 768px) {
        .play {
            -webkit-appearance: button;
            font-family: inherit;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;
            overflow: visible;
            text-transform: none;
            outline: 0;
            background-color: transparent;
            border: none;
    
            width: 96px;
            height: 96px;
            border: 5px solid #99D6EA;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            transition: all 300ms ease;
            z-index: 1;
        
            &::-moz-focus-inner {
                border-style: none;
                padding: 0;
            }
        
            &:-moz-focusring {
                outline: 1px dotted ButtonText;
            }
        
            &:focus {
                outline:0;
            }
        
            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                fill: #99D6EA;
                margin-left: 7px;
                width: 36px;
                height: 51px;
                transition: fill 300ms ease;
            }
        
            &:hover {
                border: 5px solid #ffffff;
        
                svg {
                    fill: #ffffff;
                }
            }
        }
    }

    /*
    Mobile
    >>------------->
    */

    @media screen and (max-width: 767px) {
        .play {
            display: none;
        }
    }
}