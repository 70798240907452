#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 61, 165, 0.6);
  z-index: 1090;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    border: 4px solid $white;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 0.75s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}