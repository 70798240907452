// Modal

.modal:before {
    content: "";
    display: none;
    //background: rgba($black, .4);
    background: rgba(216,216,216,0.80);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-modal-backdrop;
}
.opened:before {
    display: block;
}
.opened .modal-dialog {
    top: 43%;
    @include media-breakpoint-up(sm) {
        top: 47%;
    }
    transform: translate(-50%, -50%);
}

.modal-header {
    padding-bottom: 0;
    position: relative;

    // stylelint-disable property-no-vendor-prefix, selector-no-qualifying-type
    button.close {
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        position: absolute;
        top: 1rem;
        right: 1rem;
        transform: rotate(45deg);
        &:focus {
            outline: none;
        }
    }
}

.modal-body {
    padding: rem-calc(70) 5% rem-calc(40);
    @include media-breakpoint-up(md) {
        padding: rem-calc(100) rem-calc(20) rem-calc(50);
    }
}
.modal-dialog {
    box-align: center;
    box-shadow: 6px 6px 15px 1px rgba(0,0,0,0.50);
    padding: 0;
    position: fixed;
    top: -20%;
    left: 50%;
    background: $white;
    text-align:center;
    @include fontSize(26px);
    z-index: $zindex-modal;
    width: 90%;
    @include media-breakpoint-up(sm) {
        width: 70%;
    }
    @include media-breakpoint-up(md) {
        width: rem-calc(730);
    }
    @include media-breakpoint-up(lg) {
    }
    transform: translate(-50%, -500%);
    transition: transform 1s ease-out;
    animation: fadein 1s;

    .btn {
        width: rem-calc(200);
        margin: rem-calc(20) rem-calc(30) 0;
    }
    .modal-close {
        cursor: pointer;
        display: inline-block;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
