// GRAY

.gray {
    background: #2e2d33;//debug
    //@include make-container();
    //@include make-container-max-widths;
    @include background-2x( '../img/gray-bg', 'jpg', 1750px, 600px, center top, no-repeat );
    &__row {
        @include make-row();
        //align-items: baseline;
        //margin-top: rem-calc(-10);
        //margin-bottom: rem-calc(20);
        padding-top: rem-calc(70);
        padding-bottom: rem-calc(70);
        p, h2, h3{
            color: $white;
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h3 {
            text-transform: uppercase;
        }
        .btn {
            margin: 0 0 rem-calc(40) 0;
        }
        a {
            color: white;
            font-weight: normal;
        }
        //border: 1px dashed green;
        //background: lime;
        //border: 1px dashed silver;
        //max-width: rem-calc(680);//85%;//
        //margin-left: auto;
        //margin-right: auto;
    }

    &__cols-full {
        text-align: center;
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(md) {
            @include make-col(10);
            @include make-col-offset(1);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(6);
            @include make-col-offset(3);
        }
    }
}
